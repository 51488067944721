import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { AdminContext } from "../../Context/AdminContext";
import logoo from "../../imges/logo.png";
import { FiAlignJustify } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for eye and eye-slash
import { useQuery } from "react-query";
function PrivacyPolicyModal({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
        <div className="modal d-block" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Privacy Policy</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            We are <strong>(TOGETHER)</strong>. We are committed to protecting your privacy.
                            This Privacy Policy explains how we collect, use, disclose, and safeguard your
                            information when you visit our website{" "}

                        </p>
                        <p>
                            Please read this policy carefully. If you do not agree with the terms of this policy, please do not access the Website.
                        </p>
                        <h3>1. Information We Collect</h3>
                        <p>We may collect information about you in several ways, including:</p>
                        <h4>a) Personal Information</h4>
                        <p>
                            When you interact with our Website, we may collect personally identifiable information, such as:
                            <ul>
                                <li>Your name</li>
                                <li>Email address</li>
                                <li>Phone number</li>
                            </ul>
                        </p>
                        <h4>b) Non-Personal Information</h4>
                        <p>
                            We also collect non-personal information, such as:
                            <ul>
                                <li>IP address</li>
                                <li>Browser type and version</li>
                                <li>Device information</li>
                                <li>Pages visited on our Website</li>
                                <li>Date and time of visits</li>
                                <li>Referring URL</li>
                            </ul>
                        </p>
                        <h4>c) Cookies and Tracking Technologies</h4>
                        <p>
                            We use cookies, beacons, and other tracking technologies to improve your experience on our Website. These technologies help us analyze user behavior, remember your preferences, and deliver personalized content and advertisements.
                        </p>

                        <h3>2. How We Use Your Information</h3>
                        <p>We use the information we collect to:</p>
                        <ul>
                            <li>Provide, operate, and maintain the Website.</li>
                            <li>Communicate with you about our services, promotions, or updates.</li>
                            <li>Personalize your experience and improve our services.</li>
                            <li>Process transactions, if applicable.</li>
                            <li>Comply with legal and regulatory obligations.</li>
                        </ul>

                        <h3>3. Sharing Your Information</h3>
                        <p>
                            We do not sell your personal information. However, we may share your information with:
                        </p>
                        <ul>
                            <li>
                                <strong>Service Providers:</strong> Third-party vendors who assist with operations like hosting, payment processing, or marketing.
                            </li>
                            <li>
                                <strong>Legal Authorities:</strong> When required to comply with legal obligations or protect our rights.
                            </li>
                        </ul>

                        <h3>4. Your Rights and Choices</h3>
                        <p>You have the following rights regarding your personal information:</p>
                        <ul>
                            <li>
                                <strong>Access:</strong> Request a copy of the information we hold about you.
                            </li>
                            <li>
                                <strong>Correction:</strong> Update or correct inaccurate information.
                            </li>
                        </ul>

                        <h3>5. Data Security</h3>
                        <p>
                            We implement appropriate technical and organizational measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
                        </p>

                        <h3>6. Updates to This Policy</h3>
                        <p>
                            We may update this Privacy Policy from time to time. The updated policy will be posted on this page with the "Effective Date" revised. We encourage you to review this policy periodically.
                        </p>

                        <p>
                            By using our Website, you consent to this Privacy Policy and agree to its terms.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AddAgentAdmin() {
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    let { logOut } = useContext(AdminContext);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [newAgentDetails, setNewAgentDetails] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showWalletPassword, setShowWalletPassword] = useState(false);
    const [showConfirmWalletPassword, setShowConfirmWalletPassword] = useState(false);
    const [isPolicyAgreed, setIsPolicyAgreed] = useState(false);
    const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
    const [countries, setCountries] = useState([]); // Store countries data
    const [selectedCountry, setSelectedCountry] = useState(""); // Store selected country
    const [currentForm, setCurrentForm] = useState(1); // Tracks which form is active
    const [phone, setPhone] = useState(""); // Phone from first form
    const [code, setCode] = useState(""); // Verification code
    const [value, setValue] = useState(null); // E-Pin value
    let navigate = useNavigate();
    const [submitLoading, setSubmitLoading] = useState(false); // Loading for Submit Button
    const [verifyLoading, setVerifyLoading] = useState(false); // Loading for Verify Button
    const [epinLoading, setEpinLoading] = useState(false); // Loading for Fetch E-Pin Button
    const [newAgentDetail, setNewAgentDetail] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Extract parentId and position from URL
    const [searchParams] = useSearchParams();
    const parentId = searchParams.get("parentId") || "";
    const position = searchParams.get("position") || ""; // Default to empty string if not provided
    function getCurrentAgent() {
        return axios.get(`https://together-v.com/api/v1/agent/getAgent`, {
            withCredentials: true
        });
    }

    let { data } = useQuery("getCurrentAgent", getCurrentAgent);
    const formik = useFormik({
        initialValues: {
            firstName: "",
            secondName: "",
            thirdName: "",
            lastName: "",
            email: "",
            nationalId: "",
            phone: "",
            address: "",
            country: "",
            governorate: "",
            city: "",
            inheritorName: "",
            grandfatherInheritorName: "",
            zipCode: "",
            refId: parentId || data?.data?.data?.id,
            registeredParent: data?.data?.data?.id || "",
            direction: position || "", // Use position if provided, otherwise empty
            password: "",
            confirmPassword: "",
            walletPassword: "",
            confirmWalletPassword: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("First name is required"),
            secondName: Yup.string().required("Second name is required"),
            thirdName: Yup.string().required("Third name is required"),
            lastName: Yup.string().required("Last name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            nationalId: Yup.string()
                .length(14, "National ID must be 14 digits")
                .required("National ID is required"),
            phone: Yup.string().required("Phone number is required"),
            address: Yup.string().required("Address is required"),
            country: Yup.string().required("Country is required"),
            governorate: Yup.string().required("Governorate is required"),
            city: Yup.string().required("City is required"),
            inheritorName: Yup.string().required("Inheritor name is required"),
            grandfatherInheritorName: Yup.string().required("Grandfather inheritor name is required"),
            zipCode: Yup.string().required("ZIP code is required"),
            direction: Yup.string()
                .oneOf(["right1", "right0", "left0", "left1"], "Invalid direction")
                .required("Direction is required"),
            password: Yup.string()
                .min(8, "Password must be at least 8 characters")
                .required("Password is required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .required("Confirm Password is required"),
            walletPassword: Yup.string()
                .min(8, "Wallet Password must be at least 8 characters")
                .required("Wallet Password is required"),
            confirmWalletPassword: Yup.string()
                .oneOf([Yup.ref("walletPassword"), null], "Wallet Passwords must match")
                .required("Confirm Wallet Password is required"),
        }),
        onSubmit: async (values) => {
            setSubmitLoading(true); // Start loading
            try {
                const { confirmPassword, confirmWalletPassword, ...dataToSend } = values;

                const response = await axios.post(
                    "https://together-v.com/api/v1/agent/register",
                    dataToSend,
                    { withCredentials: true }
                );

                toast.success("Agent added successfully!");
                setPhone(values.phone);
                setNewAgentDetails(values);
                console.log("details" ,values)
                setCurrentForm(2);
            } catch (error) {
                toast.error(error.response?.data?.message || "Failed to add agent");
                console.log(error)
            } finally {
                setSubmitLoading(false); // Stop loading
            }
        },
    });
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get("https://restcountries.com/v3.1/all");
                setCountries(response.data);
            } catch (error) {
                toast.error("Failed to load countries data.");
            }
        };
        fetchCountries();
    }, []);

    // Step 2: Verify Agent
    const handleVerify = async (e) => {
        e.preventDefault();
        setVerifyLoading(true); // Start loading
        try {
            const response = await axios.post(
                "https://together-v.com/api/v1/agent/verifyAgent",
                { code, phone },
                { withCredentials: true }
            );
            toast.success("Agent verified successfully!");
            console.log("ver", response)
            setNewAgentDetail(response?.data?.data)
            console.log("detail" ,response?.data?.data)
            setCurrentForm(3);
        } catch (error) {
            toast.error(error.response?.data?.message || "Verification failed.");
            console.log(error)
        } finally {
            setVerifyLoading(false); // Stop loading
        }
    };

    // Step 3: Fetch E-Pin
    const handleEpinFetch = async () => {
        if (!code.trim()) {
            toast.error("Please enter a code first.");
            return;
        }
        setEpinLoading(true); // Start loading
        try {
            const response = await axios.get("https://together-v.com/api/v1/agent/E_PinsStats", {
                params: { epin: code },
                withCredentials: true,
            });
            if (response?.data?.data?.length > 0) {
                setValue(response.data.data[0].value);

            } else {
                toast.error("No results found for this E-Pin.");
            }
        } catch (error) {
            toast.error("Error fetching E-Pin value.");
        } finally {
            setEpinLoading(false); // Stop loading
        }
    };
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Add Agent</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center justify-content-between w-100">
                    {/* Logo */}
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/DashboardAdmin")}
                        />
                    </div>

                    {/* Language Toggle Buttons */}
                    <div className="d-flex">
                        <li className="nav-item dropdown bg-body">
                            <Link
                                className="nav-link "
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span>{t("language")}</span>
                            </Link>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("en")}
                                    >
                                        English
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("ar")}
                                    >
                                        العربية
                                    </button>
                                </li>

                            </ul>
                        </li>

                    </div>

                    {/* Sidebar Toggle */}
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
            </header>


            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>{t("allAgents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/NewId">
                            <i className="bi bi-grid"></i>
                            <span>{t("changeID")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEpin")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>{t("ePinsHistory")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addProduct")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("allProducts")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("myTree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ViewAllEventsAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("viewEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("withdrawRequests")}</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("myTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allPurchase")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allWithdraws")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/BinaryReports">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("binaryDetails")}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("filtration")}</span>
                        </Link>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>{t("allContact")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>{t("profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>{t("logout")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>

            <PrivacyPolicyModal isOpen={isPolicyModalOpen} onClose={() => setIsPolicyModalOpen(false)} />


            <div id="main" className="p-4 bg-light">
                {currentForm === 1 && (<div>
                    <div className="pagetitle">
                        <h1>{t("add_agent")}</h1>
                    </div>
                    <div className="bg-white p-4 rounded shadow">
                        <form onSubmit={formik.handleSubmit}>
                            {/* First Name */}
                            <div className="mb-3">
                                <label htmlFor="firstName" className="form-label">{t("first_name")}</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className={`form-control ${formik.touched.firstName && formik.errors.firstName ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("firstName")}
                                />
                                {formik.touched.firstName && formik.errors.firstName && (
                                    <div className="invalid-feedback">{formik.errors.firstName}</div>
                                )}
                            </div>

                            {/* Second Name */}
                            <div className="mb-3">
                                <label htmlFor="secondName" className="form-label">{t("secondName")}</label>
                                <input
                                    type="text"
                                    id="secondName"
                                    className={`form-control ${formik.touched.secondName && formik.errors.secondName ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("secondName")}
                                />
                                {formik.touched.secondName && formik.errors.secondName && (
                                    <div className="invalid-feedback">{formik.errors.secondName}</div>
                                )}
                            </div>

                            {/* Third Name */}
                            <div className="mb-3">
                                <label htmlFor="thirdName" className="form-label">{t("thirdName")}</label>
                                <input
                                    type="text"
                                    id="thirdName"
                                    className={`form-control ${formik.touched.thirdName && formik.errors.thirdName ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("thirdName")}
                                />
                                {formik.touched.thirdName && formik.errors.thirdName && (
                                    <div className="invalid-feedback">{formik.errors.thirdName}</div>
                                )}
                            </div>

                            {/* Last Name */}
                            <div className="mb-3">
                                <label htmlFor="lastName" className="form-label">{t("lastName")}</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className={`form-control ${formik.touched.lastName && formik.errors.lastName ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("lastName")}
                                />
                                {formik.touched.lastName && formik.errors.lastName && (
                                    <div className="invalid-feedback">{formik.errors.lastName}</div>
                                )}
                            </div>

                            {/* Email */}
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">{t("email")}</label>
                                <input
                                    type="email"
                                    id="email"
                                    className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className="invalid-feedback">{formik.errors.email}</div>
                                )}
                            </div>

                            {/* Country */}
                            <div className="mb-3">
                                <label htmlFor="country" className="form-label">{t("country")}</label>
                                <select
                                    id="country"
                                    className={`form-control ${formik.touched.country && formik.errors.country ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("country")}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        setSelectedCountry(e.target.value);
                                    }}
                                >
                                    <option value="">Select a country</option>
                                    {countries.map((country) => (
                                        <option key={country.cca2} value={country.name.common}>
                                            {country.name.common}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.country && formik.errors.country && (
                                    <div className="invalid-feedback">{formik.errors.country}</div>
                                )}
                            </div>

                            {/* City */}
                            <div className="mb-3">
                                <label htmlFor="city" className="form-label">{t("city")}</label>
                                {selectedCountry === "Egypt" ? (
                                    <select
                                        id="city"
                                        className={`form-control ${formik.touched.city && formik.errors.city ? "is-invalid" : ""}`}
                                        {...formik.getFieldProps("city")}
                                    >
                                        <option value="">{t("select_city")}</option>
                                        <option value="Cairo">{t("cairo")}</option>
                                        <option value="Alexandria">{t("alexandria")}</option>
                                        <option value="Giza">{t("giza")}</option>
                                        <option value="Port Said">{t("port_said")}</option>
                                        <option value="Suez">{t("suez")}</option>
                                        <option value="Gharbia">{t("Gharbia")}</option>
                                        <option value="Dakahlia">{t("Dakahlia")}</option>
                                        <option value="Ismailia">{t("ismailia")}</option>
                                        <option value="Faiyum">{t("faiyum")}</option>
                                        <option value="Damietta">{t("damietta")}</option>
                                        <option value="Asyut">{t("asyut")}</option>
                                        <option value="Aswan">{t("aswan")}</option>
                                        <option value="Minya">{t("minya")}</option>
                                        <option value="Beni Suef">{t("beni_suef")}</option>
                                        <option value="Qena">{t("qena")}</option>
                                        <option value="Sohag">{t("sohag")}</option>
                                        <option value="Hurghada">{t("hurghada")}</option>
                                        <option value="Luxor">{t("luxor")}</option>
                                        <option value="Qalyubia">{t("qalyubia")}</option>
                                        <option value="Sharqia">{t("sharqia")}</option>
                                        <option value="Beheira">{t("beheira")}</option>
                                        <option value="Kafr El Sheikh">{t("kafr_el_sheikh")}</option>
                                        <option value="Monufia">{t("monufia")}</option>
                                        <option value="Red Sea">{t("red_sea")}</option>
                                        <option value="North Sinai">{t("north_sinai")}</option>
                                        <option value="South Sinai">{t("south_sinai")}</option>
                                        <option value="Matruh">{t("matruh")}</option>
                                        <option value="New Valley">{t("new_valley")}</option>
                                    </select>
                                ) : (
                                    <input
                                        type="text"
                                        id="city"
                                        className={`form-control ${formik.touched.city && formik.errors.city ? "is-invalid" : ""}`}
                                        placeholder="Enter your city"
                                        {...formik.getFieldProps("city")}
                                    />
                                )}
                                {formik.touched.city && formik.errors.city && (
                                    <div className="invalid-feedback">{formik.errors.city}</div>
                                )}
                            </div>

                            {/* Password */}
                            <div className="mb-3">
                                <label htmlFor="nationalId" className="form-label">{t("nationalId")}</label>
                                <input
                                    type="text"
                                    id="nationalId"
                                    className={`form-control ${formik.touched.nationalId && formik.errors.nationalId ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("nationalId")}
                                />
                                {formik.touched.nationalId && formik.errors.nationalId && (
                                    <div className="invalid-feedback">{formik.errors.nationalId}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">{t("phone")}</label>
                                <input
                                    type="text"
                                    id="phone"
                                    className={`form-control ${formik.touched.phone && formik.errors.phone ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("phone")}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className="invalid-feedback">{formik.errors.phone}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address" className="form-label">{t("address")}</label>
                                <input
                                    type="text"
                                    id="address"
                                    className={`form-control ${formik.touched.address && formik.errors.address ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("address")}
                                />
                                {formik.touched.address && formik.errors.address && (
                                    <div className="invalid-feedback">{formik.errors.address}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="governorate" className="form-label">{t("governorate")}</label>
                                <input
                                    type="text"
                                    id="governorate"
                                    className={`form-control ${formik.touched.governorate && formik.errors.governorate ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("governorate")}
                                />
                                {formik.touched.governorate && formik.errors.governorate && (
                                    <div className="invalid-feedback">{formik.errors.governorate}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="inheritorName" className="form-label">{t("inheritorName")}</label>
                                <input
                                    type="text"
                                    id="inheritorName"
                                    className={`form-control ${formik.touched.inheritorName && formik.errors.inheritorName ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("inheritorName")}
                                />
                                {formik.touched.inheritorName && formik.errors.inheritorName && (
                                    <div className="invalid-feedback">{formik.errors.inheritorName}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="grandfatherInheritorName" className="form-label">{t("motherInheritorName")}</label>
                                <input
                                    type="text"
                                    id="grandfatherInheritorName"
                                    className={`form-control ${formik.touched.grandfatherInheritorName && formik.errors.grandfatherInheritorName ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("grandfatherInheritorName")}
                                />
                                {formik.touched.grandfatherInheritorName && formik.errors.grandfatherInheritorName && (
                                    <div className="invalid-feedback">{formik.errors.grandfatherInheritorName}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="zipCode" className="form-label">{t("zipCode")}</label>
                                <input
                                    type="text"
                                    id="zipCode"
                                    className={`form-control ${formik.touched.zipCode && formik.errors.zipCode ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("zipCode")}
                                />
                                {formik.touched.zipCode && formik.errors.zipCode && (
                                    <div className="invalid-feedback">{formik.errors.zipCode}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="refId" className="form-label">{t("upline_id")}</label>
                                <input
                                    type="text"
                                    id="refId"
                                    className={`form-control ${formik.touched.refId && formik.errors.refId ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("refId")}
                                    disabled={!!parentId} // Disable input if parentId has a value
                                />
                                {formik.touched.refId && formik.errors.refId && (
                                    <div className="invalid-feedback">{formik.errors.refId}</div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="registeredParent" className="form-label">{t("referral_id")}</label>
                                <input
                                    type="text"
                                    id="registeredParent"
                                    className={`form-control ${formik.touched.registeredParent && formik.errors.registeredParent ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("registeredParent")}
                                />

                                {formik.touched.registeredParent && formik.errors.registeredParent && (
                                    <div className="invalid-feedback">{formik.errors.registeredParent}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="direction" className="form-label">{t("direction")}</label>
                                <select
                                    id="direction"
                                    className={`form-control ${formik.touched.direction && formik.errors.direction ? "is-invalid" : ""}`}
                                    {...formik.getFieldProps("direction")}
                                >
                                    <option value="">{t("select_direction")}</option>
                                    <option value="right1">{t("right1")}</option>
                                    <option value="right0">{t("right0")}</option>
                                    <option value="left0">{t("left0")}</option>
                                    <option value="left1">{t("left1")}</option>
                                </select>
                                {formik.touched.direction && formik.errors.direction && (
                                    <div className="invalid-feedback">{formik.errors.direction}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">{t("password")}</label>
                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        className={`form-control ${formik.touched.password && formik.errors.password ? "is-invalid" : ""}`}
                                        {...formik.getFieldProps("password")}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                    {formik.touched.password && formik.errors.password && (
                                        <div className="invalid-feedback">{formik.errors.password}</div>
                                    )}
                                </div>
                            </div>

                            {/* Confirm Password */}
                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">{t("confirm_password")}</label>
                                <div className="input-group">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="confirmPassword"
                                        className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "is-invalid" : ""}`}
                                        {...formik.getFieldProps("confirmPassword")}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                    {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                        <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
                                    )}
                                </div>
                            </div>

                            {/* Wallet Password */}
                            <div className="mb-3">
                                <label htmlFor="walletPassword" className="form-label">{t("wallet_password")}</label>
                                <div className="input-group">
                                    <input
                                        type={showWalletPassword ? "text" : "password"}
                                        id="walletPassword"
                                        className={`form-control ${formik.touched.walletPassword && formik.errors.walletPassword ? "is-invalid" : ""}`}
                                        {...formik.getFieldProps("walletPassword")}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => setShowWalletPassword(!showWalletPassword)}
                                    >
                                        {showWalletPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                    {formik.touched.walletPassword && formik.errors.walletPassword && (
                                        <div className="invalid-feedback">{formik.errors.walletPassword}</div>
                                    )}
                                </div>
                            </div>

                            {/* Confirm Wallet Password */}
                            <div className="mb-3">
                                <label htmlFor="confirmWalletPassword" className="form-label">{t("confirm_wallet_password")}</label>
                                <div className="input-group">
                                    <input
                                        type={showConfirmWalletPassword ? "text" : "password"}
                                        id="confirmWalletPassword"
                                        className={`form-control ${formik.touched.confirmWalletPassword && formik.errors.confirmWalletPassword ? "is-invalid" : ""}`}
                                        {...formik.getFieldProps("confirmWalletPassword")}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={() => setShowConfirmWalletPassword(!showConfirmWalletPassword)}
                                    >
                                        {showConfirmWalletPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                    {formik.touched.confirmWalletPassword && formik.errors.confirmWalletPassword && (
                                        <div className="invalid-feedback">{formik.errors.confirmWalletPassword}</div>
                                    )}
                                </div>
                            </div>
                            {/* Submit */}
                            <button
                                type="button"
                                className="btn btn-link mb-3"
                                onClick={() => setIsPolicyModalOpen(true)}
                            >
                                {t("view_privacy_policy")}
                            </button>
                            <div
                                className="form-check mb-3 d-flex align-items-center"
                                style={{ flexDirection: i18n.language === "ar" ? "row-reverse" : "row" }}
                            >
                                <input
                                    type="checkbox"
                                    id="agreePolicy"
                                    className="form-check-input bg-ligh"
                                    onChange={(e) => setIsPolicyAgreed(e.target.checked)}
                                />
                                <label htmlFor="agreePolicy" className="form-check-label ms-2">
                                    {t("agree_privacy_policy")}
                                </label>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={!isPolicyAgreed || submitLoading} // Disable during loading
                            >
                                {submitLoading ? t("submitting") : t("submit")}
                            </button>
                        </form>
                    </div>
                </div>
                )}
                {currentForm === 2 && (
                    <div>
                        <h2>{t("verify_agent")}</h2>
                        <form onSubmit={handleVerify}>
                            <div className="mb-3">
                                <label>{t("verification_code")}</label>
                                <input
                                    className="form-control"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={verifyLoading} // Disable during loading
                            >
                                {verifyLoading ? t("verifying") : t("verify")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary ms-2"
                                onClick={handleEpinFetch}
                                disabled={epinLoading} // Disable during loading
                            >
                                {epinLoading ? t("fetching") : t("fetch_epin")}
                            </button>
                        </form>
                        {value && <div className="mt-3">{t("epin_value")}: {value}</div>}
                    </div>
                )}

                {/* Step 3: Display Agent Details */}
                {currentForm === 3 && (
                    <div>
                        <h2>{t("agent_details")}</h2>
                        <ul className="list-group">
                            <li className="list-group-item">
                                {t("full_name")}: {newAgentDetails.firstName} {newAgentDetails.secondName} {newAgentDetails.thirdName} {newAgentDetails.lastName}
                            </li>
                            <li className="list-group-item">
                                {t("email")}: {newAgentDetails.email}
                            </li>
                            <li className="list-group-item">
                                {t("phone")}: {newAgentDetails.phone}
                            </li>
                            <li className="list-group-item">
                                {t("national_id")}: {newAgentDetails.nationalId}
                            </li>
                            <li className="list-group-item">
                                {t("id")}: {newAgentDetail?.agent.id}
                            </li>
                            <li className="list-group-item">
                                {t("direction")}: {newAgentDetails.direction}
                            </li>
                            <li className="list-group-item">
                                {t("created_at")}: {newAgentDetail.E_Pin.createdAt.split("T")[0]}
                            </li>
                        </ul>

                    </div>
                )}
            </div>





        </>
    );
}

export default AddAgentAdmin;
