import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { AdminContext } from "../../Context/AdminContext"
import { jsPDF } from "jspdf";
import logoo from "../../imges/logo.png"
import { FiAlignJustify } from "react-icons/fi";

import { BallTriangle } from 'react-loader-spinner'
import { useTranslation } from "react-i18next";
function Agents() {
    let { logOut } = useContext(AdminContext);
    const [searchId, setSearchId] = useState("");
    const [filteredAgent, setFilteredAgent] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768); // Sidebar open for desktop by default
    let navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
    const recordsPerPage = 10; // Number of records per page
    const { t, i18n } = useTranslation(); // Translation hook
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarOpen(window.innerWidth > 768); // Open sidebar on desktop, close on mobile
        };

        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    function getAllAgents() {
        return axios.get(`https://together-v.com/api/v1/agent/allAgents`, {
            withCredentials: true,
        });
    }

    const { data, isLoading, } = useQuery("getAllAgents", getAllAgents);
    console.log(data?.data.data)
    const totalRecords = data?.data?.data?.length || 0;
    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const paginatedData = data?.data?.data?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const handlePageChange = (page) => setCurrentPage(page);
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

    const handleSearch = async () => {
        if (searchId.trim() === "") {
            setFilteredAgent(null);
            return;
        }
        try {
            const response = await axios.get(
                `https://together-v.com/api/v1/agent/getAgentByAdmin/${searchId}`,
                {
                    withCredentials: true,
                }
            );
            setFilteredAgent(response.data.data);
        } catch (error) {
            setFilteredAgent(null);
        }
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const agents = data?.data?.data;

        if (!agents || agents.length === 0) {
            return;
        }

        doc.setFontSize(14);
        doc.text("All Agents Details", 10, 10);

        let y = 20; // Starting Y position for text

        agents.forEach((agent, index) => {
            doc.setFontSize(12);
            doc.text(`Agent ${index + 1}`, 10, y);
            y += 10; // Space between agent entries

            Object.keys(agent).forEach((key) => {
                if (typeof agent[key] === "object") {
                    doc.text(`${key}: ${JSON.stringify(agent[key])}`, 10, y);
                } else {
                    doc.text(`${key}: ${agent[key]}`, 10, y);
                }
                y += 10;

                // Create a new page if the content goes beyond the page height
                if (y > 270) {
                    doc.addPage();
                    y = 10; // Reset Y position for the new page
                }
            });

            y += 10; // Extra space between agents
        });

        // Save the PDF
        doc.save(`AllAgentsDetails.pdf`);
    };
    useEffect(() => {
        if (i18n.language === "ar") {
            document.body.classList.add("rtl");
        } else {
            document.body.classList.remove("rtl");
        }

        // Cleanup on unmount
        return () => {
            document.body.classList.remove("rtl");
        };
    }, [i18n.language]);

    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <Helmet>
                    <meta charset="utf-8" />
                    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
                    <title>Agents</title>
                    <meta content="" name="description" />
                    <meta content="" name="keywords" />
                </Helmet>
                <div className="d-flex align-items-center justify-content-between w-100">
                    {/* Logo */}
                    <div>
                        <img
                            src={logoo}
                            alt="Logo"
                            className="logo d-flex align-items-center w-35"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/DashboardAdmin")}
                        />
                    </div>

                    {/* Language Toggle Buttons */}
                    <div className="d-flex">
                        <li className="nav-item dropdown bg-body">
                            <Link
                                className="nav-link "
                                to="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span>{t("language")}</span>
                            </Link>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "en" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("en")}
                                    >
                                        English
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={`btn ${i18n.language === "ar" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                                        onClick={() => changeLanguage("ar")}
                                    >
                                        العربية
                                    </button>
                                </li>

                            </ul>
                        </li>

                    </div>

                    {/* Sidebar Toggle */}
                    <h2 className="menu-icon" onClick={toggleSidebar}>
                        <FiAlignJustify />
                    </h2>
                </div>
            </header>

            <aside id="sidebar" className={`sidebar ${isSidebarOpen ? "open" : "closed"} ${i18n.language === "ar" ? "sidebar-right" : ""}`}>
                <ul className="sidebar-nav" id="sidebar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to="/DashboardAdmin">
                            <i className="bi bi-grid"></i>
                            <span>{t("dashboard")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Agents">
                            <i className="bi bi-grid"></i>
                            <span>{t("allAgents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/NewId">
                            <i className="bi bi-grid"></i>
                            <span>{t("changeID")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEpin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEpin")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EPin">
                            <i className="bi bi-grid"></i>
                            <span>E-Pins</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/EpinHistory">
                            <i className="bi bi-grid"></i>
                            <span>{t("ePinsHistory")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddProduct">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addProduct")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllProducts">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("allProducts")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/MyTreeAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("myTree")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AddEvent">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("addEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ViewAllEventsAdmin">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("viewEvents")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/WithDrawRequest">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("withdrawRequests")}</span>
                        </Link>
                    </li>

                    <li className="nav-item dropdown bg-body">
                        <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("reports")}</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/MyTransfers">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("myTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllRequestsProducts">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allPurchase")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/TransferRequests">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allTransfers")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/AllWithDrawsAdmin">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("allWithdraws")}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link collapsed" to="/BinaryReports">
                                    <i className="bi bi-box-arrow-in-down"></i>
                                    <span>{t("binaryDetails")}</span>
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/Filtration">
                            <i className="bi bi-box-arrow-in-down"></i>
                            <span>{t("filtration")}</span>
                        </Link>
                    </li>
                    <li className="nav-heading">{t("pages")}</li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/AllContactUs">
                            <i className="bi bi-person"></i>
                            <span>{t("allContact")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" to="/ProfileAdmin">
                            <i className="bi bi-person"></i>
                            <span>{t("profile")}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link collapsed" onClick={() => logOut()}>
                            <i className="bi bi-person"></i>
                            <span>{t("logout")}</span>
                        </Link>
                    </li>
                </ul>
            </aside>



            <section id="main" className={`${isSidebarOpen ? "" : "sidebar-open"}`}>
                <div >
                    <div className="pagetitle">
                        <h1>{t("agents")}</h1>
                        <button className="btn btn-primary my-3" onClick={downloadPDF}>
                            {t("downloadPDF")}
                        </button>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">{t("home")}</Link></li>
                                <li className="breadcrumb-item">{t("dashboard")}</li>
                                <li className="breadcrumb-item active">{t("agents")}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="p-4 rounded-1 bg-white shadow-3">
                        <div className="text-center mb-2">
                            <input
                                type="text"
                                placeholder={t("enterAgentID")}
                                value={searchId}
                                onChange={(e) => setSearchId(e.target.value)}
                                className="form-control w-100"
                            />
                            <button
                                className="btn btn-primary w-25 mt-3"
                                onClick={handleSearch}
                            >
                                {t("search")}
                            </button>
                        </div>
                        <div className="tabs">
                            <div className="tab-content pt-4">
                                <div
                                    className="tab-pane fade active show"
                                    id="all-booking"
                                    role="tabpanel"
                                >
                                    {isLoading ? <div className="'w-100 py-5 d-flex justify-content-center">
                                        <BallTriangle
                                            height={100}
                                            width={100}
                                            radius={5}
                                            color="#4fa94d"
                                            ariaLabel="ball-triangle-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    </div> : <>
                                        <div className="overflow-scroll scroll-bar-1">
                                            <table className="table col-12">
                                                <thead className="bg-light-2">
                                                    <tr>
                                                        <th>{t("id")}</th>
                                                        <th>{t("name")}</th>
                                                        <th>{t("email")}</th>
                                                        <th>{t("country")}</th>
                                                        <th>{t("block")}</th>
                                                        <th>{t("blockLogin")}</th>
                                                        <th>{t("blockActivate")}</th>
                                                        <th>{t("view")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredAgent ? (
                                                        <tr>
                                                            <td>{filteredAgent.id}</td>
                                                            <td>{filteredAgent.firstName} {filteredAgent.secondName} {filteredAgent.ThirdName} {filteredAgent.lastName}</td>
                                                            <td>{filteredAgent.email}</td>
                                                            <td>{filteredAgent.country}</td>
                                                            <td>{filteredAgent.block ? t("blocked") : t("active")}</td>
                                                            <td>{filteredAgent.isVerified ? t("active") : t("blocked")}</td>
                                                            <td>{filteredAgent.packageActivate ? t("active") : t("blocked")}</td>
                                                            <td>
                                                                <Link to={`/UpdateDataAgent/${filteredAgent.id}/${filteredAgent._id}`} className="btn btn-warning">View</Link>
                                                            </td>
                                                        </tr>
                                                    ) : Array.isArray(paginatedData) ? (
                                                        paginatedData.map((agent, index) => (
                                                            <tr key={agent._id}>
                                                                <td>{agent.id}</td>
                                                                <td>{agent.firstName} {agent.secondName} {agent.thirdName}  {agent.lastName}</td>
                                                                <td>{agent.email}</td>
                                                                <td>{agent.country}</td>
                                                                <td>{agent.block ? t("blocked") : t("active")}</td>
                                                                <td>{agent.isVerified ? t("active") : t("blocked")}</td>
                                                                <td>{agent.packageActivate ? t("active") : t("blocked")}</td>
                                                                <td>
                                                                    <Link to={`/UpdateDataAgent/${agent.id}/${agent._id}`} className="btn btn-warning">View</Link>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="8">{t("noDataAvailable")}</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                            <div className="d-flex justify-content-center align-items-center mt-4">
                                                <button
                                                    className="btn btn-primary me-2"
                                                    onClick={handlePreviousPage}
                                                    disabled={currentPage === 1}
                                                >
                                                    {t("previous")}
                                                </button>
                                                {[...Array(totalPages)].map((_, page) => {
                                                    const pageNumber = page + 1;
                                                    if (
                                                        pageNumber === 1 ||
                                                        pageNumber === totalPages ||
                                                        (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                                                    ) {
                                                        return (
                                                            <button
                                                                key={pageNumber}
                                                                onClick={() => handlePageChange(pageNumber)}
                                                                className={`btn mx-1 ${currentPage === pageNumber ? "btn-success" : "btn-outline-primary"}`}
                                                            >
                                                                {pageNumber}
                                                            </button>
                                                        );
                                                    } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                                                        return (
                                                            <span key={`ellipsis-${pageNumber}`} className="mx-1">
                                                                ...
                                                            </span>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <button
                                                    className="btn btn-primary ms-2"
                                                    onClick={handleNextPage}
                                                    disabled={currentPage === totalPages}
                                                >
                                                    {t("next")}
                                                </button>
                                            </div>
                                        </div>
                                    </>}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Agents;
